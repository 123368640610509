import React, { useCallback, useEffect, useState } from 'react'
import './style.css'
import IconMedium from '../../assets/icons/timi_bg2.png'
import { X } from 'react-feather'
import classNames from 'classnames'
import STATUS_CHAT from '../../constants/statusChat'
import { useContexts } from '../../contexts/globalContext'

export default function RequirePhone() {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [err, setError] = useState(false);
    const [activeChatNow, setActiveChatNow] = useState(false)
    const {status, setStatus, setRequiredPhone} = useContexts();
    
    const handleEnterChat = ()=>{
        setRequiredPhone(true);
        setStatus(STATUS_CHAT.CHATTING)
    }

    const onChangePhone = useCallback(
        (e) => {
            setPhoneNumber(e.target.value)
        }, [])
    const validatePhone = (phone) => {
        try {
            return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(phone);
        }
        catch (err) {
            return false
        }

    }

    useEffect(() => {
        let check = validatePhone(phoneNumber)

        if(phoneNumber !== "" && !check){
            setError(true)
        }
        else setError(false)

        if(check) setActiveChatNow(true)
        else setActiveChatNow(false)


    }, [phoneNumber])
    return (
        <div className={classNames('require-phone flex flex-col items-center', {hidden: !(status===STATUS_CHAT.REQUIRE_PHONE)})}>

            <div className="absolute top-1 right-1 icon-close-chat">
                <X size={20} color="#595959" fill="#F6F6F6" layout="none" onClick={()=>{setStatus(STATUS_CHAT.BEFOR_CHAT)}}/>
            </div>
            <div className="my-5">
                <img alt="icon-medium" width={"150px"} height="200px" src={"https://cms.ftech.ai/uploads/timi_bg2_998fd6c0_1a74c2ef36.png"} />
            </div>
            <p className="font-bold text-xl leading-5">
                Xin chào, mình là Timi
            </p>
            <div className="font-normal text-base leading-5 my-3">
                <p>Mình là trợ lý ảo của FTECH.AI</p>
                <p> Mình luôn sẵn sàng hỗ trợ bạn!</p>
            </div>

            <div className="my-8">
                <input className="input-require-phone py-2 px-3" onChange={onChangePhone} type="text" placeholder="Cung cấp số để thoại" />
                {
                    err && <p className="mt-1 text-xs font-normal text-red-500">
                        Số điện thoại không đúng định dạng
                    </p>
                }
            </div>
            <div className="flex flex-grow">

            </div>

            <div className="flex flex-col">
                {
                    activeChatNow
                    ?
                    <button type="button" style={{ backgroundColor: "#3DBBED" }}  onClick={handleEnterChat}>Bắt đầu</button>
                    :
                    <button type="button" style={{ backgroundColor: "#C8CCCD" }} className="cursor-not-allowed" disabled>Bắt đầu</button>

                }
                <button type="button" className="mb-8" style={{ backgroundColor: "#3DBBED" }} onClick={handleEnterChat}>Trò chuyện ngay</button>
            </div>

        </div>
    )
}
