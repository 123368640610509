/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import './style.css'
import IconX from '../../assets/icons/cross.png'
import classNames from 'classnames'
import { useContexts } from '../../contexts/globalContext'
import STATUS_CHAT from '../../constants/statusChat'
export default function Index({ hidden }) {
    const { status, setStatus, requiredPhone, questionAuto } = useContexts();
    const [showQuestionSuggest, setShowQuestionSuggest] = useState(true)
    const handleClick = () => {
        if (requiredPhone) setStatus(STATUS_CHAT.CHATTING)
        else setStatus(STATUS_CHAT.REQUIRE_PHONE)
        setShowQuestionSuggest(false)
    }
    const [showMessageAuto, setShowMessageAuto] = useState(true)

    useEffect(() => {
        if(status === STATUS_CHAT.BEFOR_CHAT) setShowQuestionSuggest(true)
    }, [questionAuto])
    return (
        <div className={classNames('wrap-logo relative', { hidden: !(status === STATUS_CHAT.BEFOR_CHAT) })}>
            <div className="small-logo-chat cursor-pointer" onClick={handleClick}>
                <img
                    src={"https://cms.ftech.ai/uploads/timi_7fb1846e_b8180cbf4e.png"}
                    alt="icon-chat"
                    width="90px"
                    height="80px"
                />
            </div>
            {
                (showQuestionSuggest && showMessageAuto)
                &&
                <div className="mesenger-autos absolute">
                    <div 
                        className='absolute top-1 right-1 font-medium leading-3 cursor-pointer hover:scale-95'
                        onClick={() => setShowMessageAuto(false)}
                    >
                        <img
                            src={IconX}
                            alt="icon-chat"
                            width="9px"
                            height="9px"
                        />
                    </div>
                    <p className="mesenger-auto-text cursor-pointer break-word" onClick={handleClick}>
                        {questionAuto?.question}
                    </p>
                </div>
            }
        </div>
    )
}
